import Container from 'components/elements/container';
import Block2View from './block2view';
import './block2.css';

function Block2() {
  const items = [
    { icon: '/images/block2/icon1.svg', title: 'Без регистрации' },
    { icon: '/images/block2/icon2.svg', title: 'Оплачивайте МСО онлайн' },
    { icon: '/images/block2/icon3.svg', title: 'История оплат' },
    { icon: '/images/block2/icon4.svg', title: 'Онлайн-чеки' },
  ];
  return (
    <div className="block block2" id="avd">
      <Container>
        <Block2View
          title="Оплачивайте мойки <br />
          самообслуживания <br />
          <b>онлайн</b>"
          desc="Больше не нужно искать мелочь для оплаты мойки, вносите депозит онлайн"
          items={items}
        />
      </Container>
    </div>
  );
}

export default Block2;
