export function init() {
  window.jQuery(function ($) {
    $(document).ready(function () {
      /*
        $.extend($.validator.messages, {
          min: 'Введите значение больше {0}',
          max: 'Введите значение меньше {0}',
          required: 'Поле обязательно',
          email: 'Неверный email',
        });
        */

      //scroll
      $(window).scroll(window.onScroll);

      // resize
      $(window).resize(function () {
        window.prepareMenuLinks();
      });

      $(window).resize();
      $(window).scroll();
    });
  });
}
