function Block3View(props: any) {
  const { items } = props;
  const itemsEl = items.map((el: any, idx: any) => {
    return (
      <div className="block3__item" key={idx}>
        <div className="block3__item-img">
          <img src={el.icon} alt="" />
        </div>
        <div className="block3__item-title">{el.title}</div>
      </div>
    );
  });

  return <div className="block3__items">{itemsEl}</div>;
}

export default Block3View;
