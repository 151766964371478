import styled, { css } from 'styled-components';

export const HeaderPlace = styled.div`
  margin: 0 0 60px;
  padding: 30px 0;
  &.fixed {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 5px 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  @media screen and (max-width: 1469px) {
    margin: 0 0 30px;
  }
  @media screen and (max-width: 991px) {
    padding: 25px 0;
    margin: 0 0 50px;
  }
`;
export const HeaderFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div`
  margin-right: 20px;
`;
export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const HeaderLogo = styled.a`
  display: block;
  transition: 0.2s all ease-in;
  max-height: 59px;
  img {
    max-height: 100%;
    width: auto;
  }
  &:hover {
    opacity: 0.75;
  }
  @media screen and (max-width: 991px) {
    height: 35px;
  }
`;
