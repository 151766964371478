import Container from 'components/elements/container';
import Block3View from './block3view';
import './block3.css';

function Block3() {
  const items = [
    { icon: '/images/block3/img1.svg', title: 'Выберите мойку' },
    { icon: '/images/block3/img2.svg', title: 'Укажите номер бокса' },
    { icon: '/images/block3/img3.svg', title: 'Внесите депозит' },
    { icon: '/images/block3/img4.svg', title: 'Помойте машину' },
  ];

  return (
    <div className="block block3" id="hiw">
      <div className="title">Как это работает?</div>
      <div className="block3__back">
        <Container>
          <Block3View items={items} />
        </Container>
      </div>
    </div>
  );
}

export default Block3;
