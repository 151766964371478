import config from 'config';
import { isMobile } from 'react-device-detect';

import { Place, Items } from './applinks.styled';

// https://stackoverflow.com/questions/39435395/reactjs-how-to-determine-if-the-application-is-being-viewed-on-mobile-or-deskto

function AppLinks(props: any) {
  const linkIos = isMobile ? config.links.appsFlyer : config.links.ios;
  const linkAndroid = isMobile ? config.links.appsFlyer : config.links.android;
  return (
    <Place className="mobile-links" {...props}>
      <Items {...props}>
        <a href={linkIos} target="_blank" rel="noreferrer">
          <img src="/images/block1/appstore.svg" alt="appstore" />
        </a>
        <a href={linkAndroid} target="_blank" rel="noreferrer">
          <img src="/images/block1/googleplay.svg" alt="googleplay" />
        </a>
        <a href={config.links.hw} target="_blank" rel="noreferrer">
          <img src="/images/block1/hw.svg" alt="huawei" />
        </a>
      </Items>
    </Place>
  );
}

AppLinks.defaultProps = {
  menu: false,
  center: false,
};

export default AppLinks;
