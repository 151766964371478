import { useEffect } from 'react';

import { init } from 'comon';

import Header from 'components/header';
import Footer from 'components/footer';
import Block1 from 'app/block1/block1';
import Block2 from 'app/block2/block2';
import Block3 from 'app/block3/block3';
import Block4 from 'app/block4/block4';
//import BlockFaq from 'app/blockfaq/blockfaq';
import BlockMail from 'app/blockmail/blockmail';

function App() {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Header />

      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      {/*<BlockFaq />*/}
      <BlockMail />

      <Footer />
    </>
  );
}

export default App;
